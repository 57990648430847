const axios = require('axios').default;
import './lib/varifyMarkup';
import { vf, typeArray } from './lib/elements.js';
import { getValue } from './lib/getValue.js';
import { isValid } from './lib/isValid.js';
import { showError, erasureError } from './lib/showError.js';
import { validation } from './lib/validation.js';
import * as Common from './lib/common.js';




/**
***  vivaForm
***  v1.6
***  2022.11.25
**/



window.vivaForm = (object) => {

  // 引数がオブジェクトでない場合は何もしない
  if( object instanceof Object === false ){
    return false;
  }

  // 引数のオブジェクトを再設定
  global.Validations = {
    form       : object.formname,
    rules      : object.rules ? object.rules : {},
    addMethod  : object.method ? object.method : {},
    group      : object.group ? object.group : {},
    value      : null
  };

  // フォーム要素を取得する
  global.vivaForm = document.forms[Validations.form];

  if( !vivaForm ) return false;

  // フォームエレメント
  const formElements = vivaForm.elements;

  // フォームの最後の子要素として追加
  const insertTextarea = '<textarea name="submitValues" hidden></textarea><input type="hidden" name="recaptchaToken" id="recaptchaToken">';
  vivaForm.insertAdjacentHTML('beforeend', insertTextarea);



  // フォームエレメントをループしてイベントをセットする
  for( const el of formElements ){
    // 変数の定義
    const name     = el.name,
          type     = el.type,
          target   = formElements[name];



    // タイプによってイベントを変える
    if( typeArray[0].includes(type) ){
      el.addEventListener('focusout', (e) => {
        validation( target );
      });
    };

    if( typeArray[1].includes(type) ){
      el.addEventListener('change', (e) => {
        validation( target );
      });
    };

  };



  // 入力内容を確認するボタンイベント
  vf.verifyExecBtn.addEventListener('click', () => {

    let values = {}, // VALUE の容れ物
        results = {},
        inValid = [], // エラーの容れ物
        positionArray = [], // スクロール用に scroll top の値を入れる容れ物
        validationResults = {}; // バリデーションの結果の容れ物


    // フォーム全体をループ処理
    for( const element of formElements ){
      // 変数の定義
      const name     = element.name,
            type     = element.type,
            el       = formElements[name];

      // button 要素は処理しない
      if( type === 'button' || type === 'submit' ) continue;

      /**
       * display none の要素は無視する
       * 親要素の display: none により内包されている子要素は display: none が継承されないため高さを取得して判別する
       * hidden 属性は除外する
       * disabled 属性が true のものは除外
       */
      if( (! element.offsetHeight && ! element.hidden) || element.disabled ) continue;

      // GET VALUE
      Validations.value = values[name] = getValue(el);

      // バリデーションの結果を取得（オブジェクトが返ってくる）
      const result = results[name] = isValid(el);

      // 確認画面 & 送信情報に表示させるため必須項目のフラグを収納する
      values[name].required = ( result.required === true ) ? true : false;

      // エラー格納
      inValid.push(result.valid);

      // エラー表示用のオブジェクトを作成する
      //
      // 通常の name  OR group の name を取得する
      const checkName = Common.getName(Validations.group, name);

      if( result.valid === false && ( checkName in validationResults ) === false ){
        validationResults[checkName] = [ result ];
        // スクロール用に scroll top の値を入れる
        if( Validations.value.scroll !== null ){
          positionArray.push(Validations.value.scroll);
        }

      }

    };


    // Validation
    //
    if( inValid.includes(false) ){
      // Validation ERROR !

      // バリデーションの結果を表示用に組み直す
      for( const key in validationResults ){

        // エラーメッセージ用の引数を作成する
        const sendArg = { [key] : validationResults[key] };
        const name    = validationResults[key][0].name;

        // エラーメッセージの表示
        showError(formElements[name], sendArg);

      }

      // エラーのある箇所へスクロールさせる
      const minPosition = Math.min(...positionArray);
      if( isFinite(minPosition) ){
        window.scrollTo({
          top: minPosition - (window.innerHeight / 3),
          left: 0,
          behavior: 'smooth'
        });
      }

    }
    // Validation OK !
    else {

      // すべてのエラー表示を消去する
      erasureError(formElements);

      console.log( values )

      // textarea[name="submitValues"] に value を設定する
      vivaForm.submitValues.value = JSON.stringify( values, null, '   ' );

      // Verify Modal
      axios({
        method: 'POST',
        url: '../verify.export.php',
        headers: {'Content-Type': 'application/json'},
        data: JSON.stringify( values )
      })
      .then(function (res) {
        Common.modalOpenExec(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    }

  });



  // 入力画面にもどるボタンイベント
  vf.close.addEventListener('click', () => {
    Common.modalCloseExec();
  });



  // 送信する
  vf.submitExecBtn.addEventListener('click', function(e){
    e.preventDefault();
    // サイトキーを取得
    const siteKey = vf.submitExecBtn.dataset.code;

    //grecaptcha.ready(function(){
    //  grecaptcha.execute( siteKey, { action: 'submit' }).then(function(token){
    //    const recaptchaToken = document.getElementById('recaptchaToken');
		//	  recaptchaToken.value = token;
    //    vivaForm.submit();
    //  });
    //});

    vivaForm.submit();

  });

};
